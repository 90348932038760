<template>
  <div class="empty-list pa-5 pt-10">
    <v-img :max-height="150" :min-height="150" contain :src="require('@/assets/empty.svg')" />
    <p
      class="text-center pt-8"
      :style="{ color: '#404040', 'font-size': '24px' }"
      v-text="$t('common.noContentYet', {
        content: content ? content.toLowerCase(): $t('common.data').toLowerCase(),
        contentAdjective: contentAdjetive ? contentAdjetive.toLowerCase() : ''
      })"
    />
  </div>
</template>

<script>
export default {
  name: 'EmptyList',
  props: {
    content: { type: String, default: null },
    contentAdjetive: { type: String, default: null },
  },
}
</script>
